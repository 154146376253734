import React, { useState, useEffect, useContext } from "react";
import { ThemeContext, PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { TRIGGER_SELECTOR, TRIGGER_LIST } from "../../constants/OnboardingTranslation";
import { TRACK_TRIGGERS, CHARTS, TRIGGER_SELECTOR_MESSAGES, SYMPTOM_TRACKER } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";
import format from "date-fns/format";

import { Col, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import theme from "../../index.scss";
import MedicalListSelector from "../MedicalListSelector";
import SubmitButton from "../StyledComponents/SubmitButton";
import ComponentWrapper from "./ComponentWrapper";
import LoadingSpinner from "../LoadingSpinner";

import { postTrackSymptom } from "../../api/TBIRequests";

import useListSelector from "../../lib/useListSelector";
/* eslint-disable react-hooks/exhaustive-deps */

const TriggerSelector = ({
  topTrigger,
  userType,
  loadTrigger,
  errorLoadingTopTrigger,
  date,
  skipTrackingStep,
  saveTrackingStep,
  loadTrackedData,
}) => {
  const history = useHistory();
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);
  const [returnedData, setReturnedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const categories = ["wellness", "travel", "screen time", "environmental", "Dietary", "situational"];

  useEffect(() => {
    const itemList = categories.map((category) => {
      const items = topTrigger
        .filter((item) => item.subcategory === category)
        .map((item) => item.factor);
      return {
        category,
        items,
      };
    });
    for (let i = 0; i < itemList.length; i++) {
      itemList[i].items.push("None");
    }

    const translatedItemList = itemList.map(itemList => {
      const { category, items } = itemList;
      // Translate each item in the items array
      const translatedItems = items.map(item =>
        TRIGGER_LIST[language][category][item]
      );
      return { category, items: translatedItems };
    });

    setReturnedData(translatedItemList);
    initialize(itemList);
  }, [topTrigger, language]);

  useEffect(() => {
    if (selectedData.none && selectedData.none.length > 0) {
      for (let category in selectedData) {
        if (selectedData[category].length > 0 && category !== "none") {
          selectedData[category].forEach((item) => {
            toggleFromSelectedList(item, selectedData[category], category);
          });
        }
      }
    }
  }, [selectedData]);

  const processDataForSending = () => {
    return Object.entries(selectedData).flatMap(([category, itemList]) => {
      return itemList.map((item) => ({
        symptom: {
          factor: item,
          category: "SDOH",
          subcategory: category,
        },
        datetimeOfSymptom: format(date, "MM-dd-yyyy"),
        hadSymptom: true,
      }));
    });
  };

  const handleSubmit = () => {
    setLoading(true)
    const data = processDataForSending();
    postTrackSymptom({
      patientId: patient.patientId,
      data,
    })
      .then(() => {
        saveTrackingStep();
        loadTrackedData();
        setLoading(false)
      })
      .catch(() => history.push("/oops"));
  };

  const tabs = {
    wellness: TRIGGER_SELECTOR[language].tabs.wellness,
    travel: TRIGGER_SELECTOR[language].tabs.travel,
    "screen time": TRIGGER_SELECTOR[language].tabs.screen_time,
    environmental: TRIGGER_SELECTOR[language].tabs.environmental,
    Dietary: TRIGGER_SELECTOR[language].tabs.dietary,
    situational: TRIGGER_SELECTOR[language].tabs.situational,
    Other: TRIGGER_SELECTOR[language].tabs.other,
  };

  const colors = {
    wellness: "#edebf3",
    travel: "#ffdadb",
    "screen time": "#ffdcaf",
    environmental: "#fff3c4",
    Dietary: "#cef3c4",
    situational: "#c1fdf7",
    // Other: "#f9d65a",
  };
  const colorsSelected = {
    wellness: "#4e3789",
    travel: "#ffdadb",
    "screen time": "#ffdcaf",
    environmental: "#fff3c4",
    Dietary: "#cef3c4",
    situational: "#c1fdf7",
    // Other: "#f9d65a",
  };

  const messages = {
    wellness: TRIGGER_SELECTOR[language].messages.wellness,
    travel: TRIGGER_SELECTOR[language].messages.travel,
    "screen time": TRIGGER_SELECTOR[language].messages.screen_time,
    environmental: TRIGGER_SELECTOR[language].messages.environmental,
    Dietary: TRIGGER_SELECTOR[language].messages.dietary,
    situational: TRIGGER_SELECTOR[language].messages.situational,
    Other: TRIGGER_SELECTOR[language].messages.other,
  };

  return (
    <>
      {errorLoadingTopTrigger ? (
        <ComponentWrapper>
          {CHARTS[language].error[0]}
          <SubmitButton onClick={loadTrigger}>
            {CHARTS[language].error[1]}
          </SubmitButton>
        </ComponentWrapper>
      ) : (
        <>
          <h6 className="d-flex justify-content-between">
            <span>
              {patient.userPatientRelationshipType == 'self'
                ? TRACK_TRIGGERS[language].patient
                : TRACK_TRIGGERS[language].non_patient[0] + patient.firstName + TRACK_TRIGGERS[language].non_patient[1]
              }
            </span>
            <span>{format(date, "LLL d, yyyy")}</span>
          </h6>
          <div
            className="p-3 m-3"
            style={{
              borderRadius: "5px",
              boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.75)",
            }}
          >
            <div className="d-flex justify-content-center">
              <h6
                className="pt-2 pb-2 pl-3 pr-3"
                style={{
                  width: "50%",
                  backgroundColor: dark ? theme.darkModePrimary : theme.primary,
                  color: dark ? theme.darkModeText : "white",
                  textAlign: "center",
                  overflowWrap: "break-word",
                }}
              >
                {userType == "patient"
                  ? TRIGGER_SELECTOR_MESSAGES[language].patient
                  : TRIGGER_SELECTOR_MESSAGES[language].non_patient[0] + patient.firstName + TRIGGER_SELECTOR[language].non_patient[1]
                }
              </h6>
            </div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="wellness">
              <Row style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "15px" }}>
                <Col sm={3} style={{ paddingRight: '0px' }}>
                  <Nav fill justify variant="tabs" className="flex-column">
                    {returnedData.map((itemList, i) => (
                      <Nav.Item key={i}
                      >
                        <Nav.Link eventKey={itemList.category}
                          style={{
                            background: `${colors[itemList.category]}`,
                            border: '1px solid #106995',

                          }}> {tabs[itemList.category]}</Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={5} style={{ paddingRight: '0px' }}>
                  <Tab.Content>
                    {returnedData.map((itemList, i) => (
                      <Tab.Pane eventKey={itemList.category}
                      >
                        <MedicalListSelector
                          patient={patient}
                          key={itemList.category}
                          category={itemList.category}
                          message={messages[itemList.category]}
                          list={itemList.items}
                          index={i}
                          selectedList={selectedData[itemList.category]}
                          toggleFromSelectedList={toggleFromSelectedList}
                          selectOne={switchSingleSelection}
                          addOther={addOther}
                          noOther={itemList.category === "none"}
                          noSuffix={itemList.category === "none"}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
                <Col sm={4}>
                  <div className="justify-content-left" style={{
                    height: "30rem",
                    overflow: 'scroll',
                    color: 'black',
                    border: '1px solid #106995',
                    textAlign: "left",
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: "5px",
                    margin: ".25rem 0",
                  }}>
                    {Object.entries(selectedData).flatMap(([category, itemList]) => {
                      return itemList.map((item) => (
                        <div style={{ margin: '0.5rem 0' }}>
                          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>icon/action/check_ccircle_24px</title>
                            <defs>
                              <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z" id="path-1"></path>
                            </defs>
                            <g id="icon/action/check_ccircle_24px" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                              </mask>
                              <use id="-↳Color" fill={colorsSelected[category]} xlinkHref="#path-1"></use>
                            </g>
                          </svg>
                          {" "}
                          {item}
                        </div>));
                    })}
                  </div>
                </Col>
              </Row>
            </Tab.Container>
            <div
              style={{ flexGrow: "1", alignItems: "flex-end" }}
              className="d-flex justify-content-between"
            >
              <SubmitButton
                onClick={skipTrackingStep}
                style={{ height: "max-content" }}
                type="button"
              >
                {SYMPTOM_TRACKER[language].skip}
              </SubmitButton>

              {loading ?
                <LoadingSpinner /> :
                <SubmitButton
                  style={{ height: "max-content" }}
                  onClick={handleSubmit}
                >
                  {SYMPTOM_TRACKER[language].save}
                </SubmitButton>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TriggerSelector;
